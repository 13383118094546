.c-layout__main {
  padding-top: 65px;
  max-width: 1366px;
  min-height: calc(100vh - 65px); //394
  overflow-x: hidden;
}
.c-account-layout__main {
  min-height: calc(100vh - 108px) !important; //394
}
@media only screen and (min-width: 768px) {
  .c-private-layout__main,
  .c-default-layout__main.c-show-side-bar {
    //padding-left: 88px !important;
  }
}

.footer {
  background-color: $footer-bg;
  position: relative;
  z-index: 2;

  &.footer-text {
    font-family: $cerebrisans-book-font;
  }
  &.footer-rights-reserved {
    font-family: $cerebrisans-regular-font;
  }
  &.footer-contact-us {
    font-family: $cerebrisans-regular-font;
    font-size: 18px;
  }
}

.log-in {
  .MuiButtonBase-root.MuiButton-containedPrimary,
  .button.MuiButtonBase-root,
  a.MuiButtonBase-root {
    width: 145px;
    height: 51px;
  }
}
.header a {
  color: #ffffff;
}
.country-label {
  z-index: 1;
  pointer-events: none;

  &.MuiInputLabel-shrink {
    transform: translate(12px, 10px) scale(0.75);
  }
}

.verify-img {
  position: absolute;
  top: 0;
  right: -20px;
  width: 60px;
}

.MuiButtonBase-root.info-icon {
  display: inline-block;
  border: 1px solid #a0a0a0;
  color: #a0a0a0;
  border-radius: 50%;
  font-size: 10px;
  line-height: 11px;
  width: 13px;
  min-width: 13px;
  height: 13px;
  text-align: center;
  font-weight: normal;
  text-transform: lowercase;
  padding: 0;

  &.verify-info {
    position: absolute;
    bottom: 15px;
    right: calc(50% - 40px);
  }
}

.MuiButtonBase-root.info-svg-icon {
  color: #a0a0a0;
  width: 13px;
  min-width: 13px;
  height: 13px;
  padding: 0;
}
