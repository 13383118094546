@import "../variables";

span.MuiSlider-root {
  width: 67%;
  display: block;
  margin: 0 0 0 20px;

  .MuiSlider-rail {
    height: 6px;
    opacity: 1;
    background-color: #efefef;
  }

  .MuiSlider-track {
    height: 6px;
    background-color: $primary-color;
  }

  .MuiSlider-thumb {
    height: 12px;
    width: 12px;
    background-color: $secondary-color;

    &:focus,
    &:hover,
    &:active {
      boxshadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)";
    }
  }

  .MuiSlider-valueLabel {
    top: 22px;
    & * {
      background: transparent;
      color: #000000;
    }
  }
}
