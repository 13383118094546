.home {
  padding: 0;
  .main-title {
    margin-top: 75px;
    font-size: 60px;
  }
  .circle-bg {
    overflow: hidden;
    margin-left: -15px;
    padding: 2px;
  }
  .who-we-represent-img {
    width: 100px;
    height: 100px;
  }
  .h2--smaller {
    line-height: 30px;
  }
  .tool-description {
    font-size: 15px;
    font-weight: 600;
  }
}
