@import "assets/stylesheets/variables";

@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/scss/main";

@import "assets/stylesheets/fonts";
@import "assets/stylesheets/colors";
@import "assets/stylesheets/typography";
@import "assets/stylesheets/common";

@import "assets/stylesheets/components/index";

@import "assets/stylesheets/layouts/layout";
@import "assets/stylesheets/layouts/forms";

@import "assets/stylesheets/pages/home";
@import "assets/stylesheets/pages/account";
@import "~rsuite/dist/rsuite.css";
