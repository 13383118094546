.side-bar {
  .MuiDrawer-paper {
    background-color: #34404a;
    top: 93px;
    left: auto;

    .MuiListItemIcon-root {
      display: flex;
      justify-content: center;
    }
  }
}
@media screen and (max-width: 700px) {
  .side-bar {
    .MuiPaper-root {
      height: auto;
      width: 100%;
      .MuiList-root {
        display: flex;
        margin-top: 0px !important;
      }
    }
  }
}
