.account {
  div.account-tabs {
    button.MuiButtonBase-root,
    a.MuiButtonBase-root {
      width: 83px;
    }
  }

  @media only screen and (min-width: 960px) {
    .register-form,
    .Login {
      .MuiFormControl-root .MuiFilledInput-root.Mui-focused,
      .MuiFormControl-root .MuiFilledInput-root {
        height: 43px;
        width: 393px;
        box-shadow: 0px 3px 6px #00000029;
      }
      .MuiFormControl-root {
        padding-bottom: 20px !important;
      }
      //.name-fields{
      //    width: 393px;
      //    .MuiFormControl-root .MuiFilledInput-root.Mui-focused, .MuiFormControl-root .MuiFilledInput-root{
      //        height: 43px;
      //        width: 186px;
      //    }
      //}
      label.MuiInputLabel-formControl {
        top: -5px;
      }
    }
    .country-select,
    .province-select,
    .city-select {
      select {
        width: 369px;
        padding: 14px 12px 10px;
      }
    }
  }
}
.country-select_container,
.province-select_container,
.city-select_container {
  padding-top: 28px !important;
}
