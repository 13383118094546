//@import "variables";

.u-color-primary {
  color: $primary-color !important;
}

.u-bg-primary {
  background-color: $primary-color !important;
}

.u-color-gray {
  color: $gray !important;
}

.u-bg-gray {
  background-color: $gray !important;
}

.u-color-secondary {
  color: $secondary-color !important;
}

.u-bg-secondary {
  background-color: $secondary-color !important;
}

.u-color-black {
  color: $black !important;
}

.u-bg-black {
  background-color: $black !important;
}

.u-color-white {
  color: $white !important;
}

.u-bg-white {
  background-color: $white !important;
}

.u-color-dark-gray {
  color: $home-tools-headers !important;
}

.u-color-darkish-gray {
  color: $text-color !important;
}
