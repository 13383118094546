@import "../variables";

.MuiTabs-root {
  .MuiTabs-scroller {
    .MuiTabs-indicator {
      background-color: $secondary-color;
    }

    .MuiTab-textColorInherit {
      .MuiTab-wrapper {
        font-weight: bold;
        color: $secondary-color;
      }
    }
  }
}
