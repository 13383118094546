.SideMenu {
  .MuiPaper-root {
    width: 467px;
  }

  .close-button {
    position: absolute;
    top: 30px;
    right: 80px;
    color: #acacac;
    min-width: 26px;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .left-arrow {
    width: 35px;
    height: 35px;
  }
}
