body {
  background-color: $primary-color;
}
.box-text {
  font-family: $cerebrisans-book-font;
  width: 541px;
  font-size: 14px;
  margin-right: 32px;
}
.industries-blocks {
  font-size: 11px !important;
}
.tags-block {
  font-size: 11px !important;
}
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 2px solid #ccc;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.296);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.296);
  background-color: #f5f5f5;
}
.footer {
  background-color: transparent !important;
}
.card-dropdown-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
  border-radius: 0.5rem;
}

.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;

  > .col,
  > [class*="col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
