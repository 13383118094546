//@import "variables";
body {
  color: $text-color;
}

h1,
.h1 {
  font-family: $cerebrisans-bold-font;
  font-size: 40px;
}

h2,
.h2 {
  font-family: $cerebrisans-bold-font;
  font-size: 25px;
  &.h2--smaller {
    font-size: 20px;
  }
}
h3 {
  font-family: $cerebrisans-bold-font;
  font-size: 18px;
}

h5 {
  font-size: 15px;
  font-family: $lato-bold-font;
}

h6 {
  font-size: 18px;
  font-family: $cerebrisans-book-font;
}

p {
  font-size: 18px;
  font-weight: lighter;
  font-family: $cerebrisans-book-font;
  word-wrap: break-word;

  &.p--small {
    font-size: 16px;
  }

  &.p--exterm-small {
    font-size: 12px;
  }

  &.p--large {
    font-size: 20px;
  }
}

li {
  word-wrap: break-word !important;
}

ol.welcome-steps {
  width: 300px;
  text-align: left;
  margin: 0 auto;
}

// h2 {
//     font-size: 19px;
//     font-weight: bold;
//     color: $primary-color-1;
// }

// h3 {
//     font-size: 19px;
//     font-weight: bold;
//     color: $primary-color-1;
// }

// .h1 {
//   font-family: 'Roboto', sans-serif;
//   font-size: 33px;
//   font-weight: bold;
//   color: $black-1;
// }

// p {
//     font-size: 17px;
//     color: #525d65;
// }

a.MuiLink-root {
  color: $link-color;
}

span.smaller-font {
  font-size: 14px;
}
