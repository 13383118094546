.CustomDialog {
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 120px);
  }
  .MuiDialog-paper {
    padding: 45px;
    border-radius: 12px;
    //width: 1232px !important;
    //margin-top: 110px !important;
    //margin-left: 100px !important;
  }
  .MuiDialog-paperWidthSm,
  .MuiPaper-root {
    width: 100% !important;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 17px;
    color: #acacac;
    width: 26px;
    min-width: 26px;
    height: 26px;
    box-shadow: 0px 3px 6px #0000001a;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
  }
}

.video-dialog {
  .Loading {
    height: 100%;
  }
  .MuiDialog-paper {
    max-width: 75%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #707070;
  }
  .dialog-content {
    height: 100%;
  }

  .close-button {
    width: auto;
    font-size: 16px;
    font-weight: bold;
    left: 0;
    border-radius: 0;
    right: auto;
    margin-left: 20px;
    box-shadow: none;
    color: $text-color;
  }
}

.passport-verification,
.search-target {
  .search-target-content,
  .passport-verification-content {
    .MuiButtonBase-root {
      height: 45px;
      width: 160px;
      font-weight: normal;
    }
  }
  .MuiDialog-paperWidthSm {
    max-width: 787px !important;
  }
}

.verification-success {
  .MuiDialog-paperWidthSm {
    max-width: 425px !important;
  }
}

.seller-filter-dialog {
  .MuiDialog-paperWidthSm {
    max-width: 700px !important;
  }
}
