.navigation {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  margin-right: 3rem;

  .navigation-menu {
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      gap: 3rem;

      li {
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
          color: white;
          text-decoration: none;
        }
        :hover {
          a {
            text-decoration: underline !important;
          }
        }
      }
      li.active {
        a {
          text-decoration: underline !important;
        }
      }
    }
  }
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;

  &:hover {
    background-color: #2642af;
  }
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .navigation-menu {
    display: none;
  }

  .navigation-menu.expanded {
    display: block;
    box-shadow: 0 4px 4px #ccc;
    position: fixed;
    right: 0;
    top: 65px;
    background-color: #f9fbfd;
    width: 100%;
    border-top: 1px solid #52708b;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0rem !important;

      :not(:last-child) {
        border-bottom: 1px solid #eee;
      }

      li {
        width: 100%;
        justify-content: flex-start !important;
        background-color: #f9fbfd;
        padding: 0.7rem 1rem;

        a {
          color: black !important;
        }
      }
    }
  }
}
