@import "../variables";
.MuiButtonBase-root {
  &.MuiButton-containedPrimary,
  &.MuiButton-containedPrimary:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $secondary-color;
  }

  &.MuiButton-containedSecondary,
  &.MuiButton-containedSecondary:hover {
    background-color: $secondary-color;
    border-color: $secondary-color;
    color: $primary-color;
  }
}

button,
a {
  &.MuiButtonBase-root {
    height: 51px;
    width: 240px;
    font-weight: bold;
    font-size: 16px;
    border-radius: 26px;
    text-transform: none;
    &.small-button {
      width: 170px;
    }

    &:focus {
      outline: none;
    }
  }

  .MuiButton-label {
    width: 100%;
  }
}

.dealconnect-btn {
  font-size: 14px !important;
}
