input[type="radio"] {
  position: relative !important;
  border: none;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  margin-top: 0;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #409be3;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:after {
    position: relative;
    top: 0;
    border: 4px solid #bbb;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -webkit-transition: 240ms;
    transition: 240ms;
    margin-right: 5px;
  }

  &:checked {
    &:before {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }

    &:after {
      border-color: #409be3;
    }
  }
}

.form-check-label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
