.react-multi-carousel-list {
  padding: 0;
  position: static !important;
  max-width: 100% !important;

  p {
    font-size: 16px;
    margin: 0;
    //min-height: 100px !important; /*ToDo: maybe a better way to handle it*/
  }
  .react-multiple-carousel__arrow {
    background-color: $primary-color;
    width: 40px;
    z-index: 1;
    height: 40px;
    min-width: 0;
    min-height: 0;
    padding: 0;

    &:before {
      color: $text-color;
      line-height: 15px;
      font-size: 15px;
    }

    &:hover {
      background-color: $primary-color;
    }
  }

  .react-multiple-carousel__arrow--left {
    left: -20px;

    &:before {
      content: "\2BC7";
    }
  }

  .react-multiple-carousel__arrow--right {
    right: -20px;

    &:before {
      content: "\2BC8";
    }
  }

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
    padding: 0 15px;
  }
}
