@import "../variables";

.MuiFormControl-root {
  width: 100%;
  padding-bottom: 10px !important;

  .MuiFilledInput-root.Mui-focused,
  .MuiFilledInput-root {
    background-color: white;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $secondary-color !important;
    &.Mui-error {
      color: $danger !important;
    }
  }
}
