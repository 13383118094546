.form-container {
  height: 846.188px;
  background-color: rgba($secondary-color, 0.9);
}

.form-title {
  font-size: 13px;
  text-transform: uppercase !important;
  margin-bottom: 15px;
  margin-top: 30px;
  &-signin {
    margin-bottom: 60px;
  }
}

.form-message {
  font-size: 26px;
  text-transform: uppercase !important;
  &-large-text {
    font-size: 21px;
  }
}

input.form-field,
input.form-field::placeholder {
  // width: 499.112px;
  height: 42px;
  color: $form-control-text-color !important;
  background-color: $secondary-color;
  font-size: 15px;
}

.form-eye-password-icon {
  color: $password-eye-color !important;
}

.error-message {
  color: $danger;
  font-size: 14px !important;
  line-height: 14px;
  margin-top: -6px;
}
